import React from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import get from 'lodash/get'
import Layout from '../components/Layouts/layout.boards.js'
import { GlobalStyle } from '../utils/global'

const pStyle = {
  fontSize: '15px',
  textAlign: 'center',
  display: 'inlineTable',
}

const propTypes = {
  data: PropTypes.object.isRequired,
}

class BoardTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      data2: [],
      data3: [],
      data4: [],
      fourColTableData: [],
      fourColTableData2: [],
      fourColTableData3: [],
      fourColTableData4: [],
      fourColTableName: '',
      fourColTableName2: '',
      fourColTableName3: '',
      fourColTableName4: '',
      fourColFooterName: '',
      fiveColTableData: [],
      fiveColTableName: '',
      fiveColTableData2: [],
      fiveColTableName2: '',
      sevenColTableData: [],
      sevenColTableData2: [],
      sevenColTableName: '',
      sevenColTableName2: '',
      eightColTableData: [],
      eightColTableName: '',
      eightColTableData2: [],
      eightColTableName2: '',
      eightColTableData3: [],
      eightColTableName3: '',
      threeColTableData: [],
      threeColTableName: '',
      areaData: [],
      areaData2: [],
      areaData3: [],
      mtlData: [],
      mtlData2: [],
      mtlData3: [],
      mtlData4: [],
      chatData: [],
      chatData2: [],
      chartData: [],
      chartData2: [],
      chartData3: [],
      chartData4: [],
      sharedEmpChart: [],
      sharedEmpChartB: [],
      sharedEmpChartC: [],
      sharedConChart: [],
      sharedIRChart: [],
      sharedMGRChart: [],
      sharedMGRChartB: [],
      sharedMGRChartC: [],
      boardCharts: [],
      boardChartsB: [],
      boardChartsC: [],
      name1: '',
      name2: '',
      name3: '',
      name4: '',
      muskField1: '',
      muskField2: '',
      muskField3: '',
      muskField4: '',
      showIframe: false,
      csvIframe: '',
      employmentSharedC: [],
      interestRateSharedContent: [],
      migrationSharedC: [],
      ccChart: [],
    }
  }
  getYear() {
    return new Date().getFullYear()
  }
  componentDidMount() {
    const slug = this.props.pageContext.slug
    if (slug == 'chat') {
      this.setState({
        eightColTableData: this.props.data.ChatHome1.edges,
        eightColTableName: 'Percentage change compared to',
        eightColTableData2: this.props.data.ChatHome2.edges,
        eightColTableName2: 'Percentage change compared to',
      })
    } else if (slug == 'area') {
      this.setState({
        /* fourColTableData: this.props.data.AreaHome1.edges,
        fourColTableData2: this.props.data.AreaHome2.edges,
        fourColTableName: 'Alberta Monthly Summary',
        fourColTableName2: 'Alberta Year-To-Date Summary', */
      })
    } else if (slug == 'barr') {
      this.setState({
        /*sevenColTableData: this.props.data.BarrHome.edges,
        sevenColTableName: 'Residential Sales and Average Price by Area',*/
      })
    } else if (slug == 'edmo') {
      // No table at home page
      this.setState({})
    } else if (slug == 'musk') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.MuskHome1.edges,
        fourColTableData2: this.props.data.MuskHome2.edges,
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
        sevenColTableData: this.props.data.MuskHome3.edges,
        sevenColTableData2: this.props.data.MuskHome4.edges,

        sevenColTableName:
          'Detailed – Non-Waterfront Residential Sales and Median Price by Area / Non-Waterfront Residential',
        sevenColTableName2:
          'Detailed – Waterfront Sales and Median Price by Area / Waterfront',
        fourColFooterName:
          'Includes transactions in all areas recorded by The Lakelands Association of REALTORS®',
      })
    } else if (slug == 'brnd') {
      // No table at home page
      this.setState({})
    } else if (slug == 'banc') {
      // No table at home page
      this.setState({})
    } else if (slug == 'treb') {
      // No table at home page
      this.setState({})
    } else if (slug == 'brnt') {
      // No table at home page
      this.setState({})
    } else if (slug == 'cari') {
      // No table at home page
      this.setState({})
    } else if (slug == 'calg') {
      // No table at home page
      this.setState({})
    } else if (slug == 'camb') {
      // No table at home page
      this.setState({})
    } else if (slug == 'corn') {
      // No table at home page
      this.setState({})
    } else if (slug == 'fort') {
      // No table at home page
      this.setState({})
    } else if (slug == 'chil') {
      // No table at home page
      this.setState({})
    } else if (slug == 'fras') {
      // No table at home page
      this.setState({})
    } else if (slug == 'grey') {
      // No table at home page
      this.setState({})
    } else if (slug == 'hami') {
      // No table at home page
      this.setState({})
    } else if (slug == 'huro') {
      // No table at home page
      this.setState({})
    } else if (slug == 'kaml') {
      // No table at home page
      this.setState({})
    } else if (slug == 'koot') {
      // No table at home page
      this.setState({})
    } else if (slug == 'leth') {
      // No table at home page
      this.setState({})
    } else if (slug == 'leth-migration') {
      // No table at home page
      this.setState({})
    } else if (slug == 'medi') {
      // No table at home page
      this.setState({})
    } else if (slug == 'king') {
      // No table at home page
      this.setState({})
    } else if (slug == 'kawa') {
      // No table at home page
      this.setState({})
    } else if (slug == 'pete') {
      // No table at home page
      this.setState({})
    } else if (slug == 'otta') {
      // No table at home page
      this.setState({})
    } else if (slug == 'okan') {
      // No table at home page
      this.setState({})
    } else if (slug == 'oakv') {
      // No table at home page
      this.setState({})
    } else if (slug == 'nbreb') {
      this.setState({
        fiveColTableData: this.props.data.NbreaHome1.edges,
        fiveColTableName: 'New Brunswick Monthly Summary',
      })
    } else if (slug == 'stca') {
      // No table at home page
      this.setState({})
    } else if (slug == 'miss') {
      // No table at home page
      this.setState({})
    } else if (slug == 'monc') {
      this.setState({
        eightColTableData: this.props.data.MoncHome1.edges,
        eightColTableName: 'MLS® Home Price Index / Percentage change vs.',
        eightColTableData2: this.props.data.MoncHome2.edges,
        eightColTableName2: 'MLS® Home Price Index / Percentage change vs.',
        eightColTableData3: this.props.data.MoncHome3.edges,
        eightColTableName3: 'MLS® Home Price Index / Percentage change vs.',
      })
    } else if (slug == 'nonb') {
      // No table at home page
      this.setState({})
    } else if (slug == 'peia') {
      // No table at home page
      this.setState({})
    } else if (slug == 'quin') {
      // No table at home page
      this.setState({
        sevenColTableData: this.props.data.QuinHome.edges,
        sevenColTableName: 'Residential Sales and Average Price by Region',
      })
    } else if (slug == 'sjnb') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sarn') {
      // No table at home page
      this.setState({})
    } else if (slug == 'regi') {
      // No table at home page
      this.setState({})
    } else if (slug == 'redd') {
      // No table at home page
      this.setState({})
    } else if (slug == '') {
      // No table at home page
      this.setState({
        showIframe: true,
      })
    } else if (slug == 'tester') {
      // No table at home page
      this.setState({})
    } else if (slug == 'stjo') {
      // No table at home page
      this.setState({
        threeColTableData: this.props.data.StjoHome1.edges,
        threeColTableName: 'Newfoundland and Labrador Monthly Summary',
      })
    } else if (slug == 'renf') {
      // No table at home page
      this.setState({})
    } else if (slug == 'nsar') {
      // No table at home page
      this.setState({
        fiveColTableData: this.props.data.NsarHome1.edges,
        fiveColTableName: 'Nova Scotia Monthly Summary',
        fiveColTableData2: this.props.data.NsarHome2.edges,
        fiveColTableName2: 'Nova Scotia Year-To-Date Summary',
      })
    } else if (slug == 'sask') {
      // No table at home page
      this.setState({})
    } else if (slug == 'simc') {
      // No table at home page
      this.setState({})
    } else if (slug == 'saul') {
      // No table at home page
      this.setState({})
    } else if (slug == 'orea') {
      // No table at home page
      this.setState({
        fiveColTableData: this.props.data.OreaHome1.edges,
        fiveColTableName: 'Ontario Monthly Summary',
        fiveColTableData2: this.props.data.OreaHome2.edges,
        fiveColTableName2: 'Ontario Year-To-Date Summary',
      })
    } else if (slug == 'sore') {
      // No table at home page
      this.setState({})
    } else if (slug == 'coll') {
      // No table at home page

      this.setState({
        /* sevenColTableData: this.props.data.CollHome1.edges,
        sevenColTableData2: this.props.data.CollHome2.edges,
        sevenColTableName:
          'Western District - Residential Sales and Average Price by Area',
        sevenColTableName2:
          'Eastern District - Residential Sales and Average Price by Area', */
      })
    } else if (slug == 'sudb') {
      // No table at home page
      this.setState({})
    } else if (slug == 'thun') {
      // No table at home page
      this.setState({})
    } else if (slug == 'vani') {
      // No table at home page
      this.setState({})
    } else if (slug == 'noba') {
      // No table at home page
      this.setState({})
    } else if (slug == 'powe') {
      // No table at home page
      this.setState({})
    } else if (slug == 'vanc') {
      // No table at home page
      this.setState({})
    } else if (slug == 'cobo') {
      // No table at home page
      this.setState({})
    } else if (slug == 'vict') {
      // No table at home page
      this.setState({})
    } else if (slug == 'wind') {
      // No table at home page
      this.setState({})
    } else if (slug == 'winn') {
      // No table at home page
      this.setState({})
    } else if (slug == 'cent') {
      // No table at home page
      this.setState({})
    } else if (slug == 'wood') {
      // No table at home page
      this.setState({})
    } else if (slug == 'lond') {
      // No table at home page
      this.setState({})
    } else if (slug == 'fred') {
      // No table at home page
      this.setState({})
    } else if (slug == 'gran') {
      // No table at home page
      this.setState({
        /*  fiveColTableData: this.props.data.GranHome1.edges, */
      })
    } else if (slug == 'guel') {
      // No table at home page
      this.setState({})
    } else if (slug == 'osha') {
      // No table at home page
      this.setState({})
    } else if (slug == 'kitc') {
      // No table at home page
      this.setState({})
    } else if (slug == 'broo') {
      // No table at home page
      this.setState({})
    } else {
      this.setState({
        data: [],
        data1: [],
        data2: [],
        data3: [],
        // chartData: [],
        areaData: [],
        areaData2: [],
        areaData3: [],
        mtlData1: [],
        mtlData2: [],
        mtlData3: [],
        mtlData4: [],
        chatData: [],
        chatData2: [],
        muskData: [],
        muskData2: [],
        fourColTableData: [],
        fiveColTableData: [],
        fiveColTableData2: [],
        sevenColTableData: [],
        sevenColTableData2: [],
        eightColTableData: [],
        eightColTableData2: [],
        eightColTableData3: [],
        threeColTableData: [],
      })
    }

    let chartOne = this.props.data.contentfulBoards.boardContentChartsA
    let chartTwo = this.props.data.contentfulBoards.boardContentChartsB
    let chartThree = this.props.data.contentfulBoards.boardContentChartsC

    let empChart = this.props.data.contentfulBoards.employmentSharedC
    let empChartB = this.props.data.contentfulBoards.employmentSharedC
    let empChartC = this.props.data.contentfulBoards.employmentSharedC
    let irChart = this.props.data.contentfulBoards.interestRateSharedContent
    let migChart = this.props.data.contentfulBoards.migrationSharedC
    let migChartB = this.props.data.contentfulBoards.migrationSharedC
    let migChartC = this.props.data.contentfulBoards.migrationSharedC
    let ccChart = this.props.data.contentfulBoards.sharedContent

    chartOne = chartOne
      ? Object.entries(chartOne).map(e => ({ image: e[1] }))
      : []
    chartTwo = chartTwo
      ? Object.entries(chartTwo).map(e => ({ image: e[1] }))
      : []
    chartThree = chartThree
      ? Object.entries(chartThree).map(e => ({ image: e[1] }))
      : []
    empChart =
      empChart && empChart.employmentCharts
        ? Object.entries(empChart.employmentCharts).map(e => ({ image: e[1] }))
        : []
    empChartB =
      empChartB && empChartB.employmentChartsB
        ? Object.entries(empChartB.employmentChartsB).map(e => ({
          image: e[1],
        }))
        : []
    empChartC =
      empChartC && empChartC.employmentChartsC
        ? Object.entries(empChartC.employmentChartsC).map(e => ({
          image: e[1],
        }))
        : []

    migChart =
      migChart && migChart.migrationCharts
        ? Object.entries(migChart.migrationCharts).map(e => ({ image: e[1] }))
        : []

    migChartB =
      migChartB && migChartB.migrationChartsB
        ? Object.entries(migChartB.migrationChartsB).map(e => ({ image: e[1] }))
        : []

    migChartC =
      migChartC && migChartC.migrationChartsC
        ? Object.entries(migChartC.migrationChartsC).map(e => ({ image: e[1] }))
        : []

    irChart =
      irChart && irChart.interestRateCharts
        ? Object.entries(irChart.interestRateCharts).map(e => ({ image: e[1] }))
        : []
    ccChart =
      ccChart && ccChart.consumerConfidenceCharts
        ? Object.entries(ccChart.consumerConfidenceCharts).map(e => ({
          image: e[1],
        }))
        : []
    this.setState({
      boardCharts: chartOne,
      boardChartB: chartTwo,
      boardChartC: chartThree,
      employmentSharedC: empChart,
      employmentChartsB: empChartB,
      employmentChartsC: empChartC,
      interestRateSharedContent: irChart,
      migrationSharedC: migChart,
      migrationChartsB: migChartB,
      migrationChartsC: migChartC,
      ccChart: ccChart,
    })
  }
  render() {
    const post = get(this.props, 'data.contentfulBoards')
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    console.log(this.props, 'boardJS========')
    const {
      data,
      data2,
      data3,
      data4,
      name1,
      name2,
      name3,
      name4,
      muskField1,
      muskField2,
      muskField3,
      muskField4,
      abfield1,
      abfield2,
      abfield3,
      abfield4,
      areaData,
      areaData2,
      areaData3,
      mtlData,
      mtlData2,
      mtlData3,
      mtlData4,
      chatData,
      chatData2,
      chartData,
      chartData2,
      chartData3,
      chartData4,
      muskData,
      muskData2,
      muskData3,
      muskData4,
      fourColTableData,
      fourColTableData2,
      fourColTableData3,
      fourColTableData4,
      fourColTableName,
      fourColTableName2,
      fourColTableName3,
      fourColTableName4,
      fourColFooterName,
      fiveColTableData,
      fiveColTableName,
      fiveColTableData2,
      fiveColTableName2,
      threeColTableData,
      threeColTableName,
      sevenColTableData,
      sevenColTableData2,
      sevenColTableName,
      sevenColTableName2,
      eightColTableData,
      eightColTableName,
      eightColTableData2,
      eightColTableName2,
      eightColTableData3,
      eightColTableName3,
      showIframe,
      csvIframe,
      boardCharts,
      boardChartB,
      boardChartC,
      employmentSharedC,
      employmentChartsB,
      employmentChartsC,
      migrationSharedC,
      migrationChartsB,
      migrationChartsC,
      interestRateSharedContent,
      ccChart,
    } = this.state
    return (
      <Layout
        showIframe={showIframe}
        data={this.props.data}
        location={this.props.location}
        board={true}
        name1={name1}
        name2={name2}
        name3={name3}
        name4={name4}
        muskField1={muskField1}
        muskField2={muskField2}
        muskField3={muskField3}
        muskField4={muskField4}
        abfield1={abfield1}
        abfield2={abfield2}
        abfield3={abfield3}
        abfield4={abfield4}
        post={post}
        tableData={data}
        tableData2={data2}
        tableData3={data3}
        tableData4={data4}
        areaData={areaData}
        areaData2={areaData2}
        areaData3={areaData3}
        mtlData={mtlData}
        mtlData2={mtlData2}
        mtlData3={mtlData3}
        mtlData4={mtlData4}
        chatData={chatData}
        chatData2={chatData2}
        chartData={chartData}
        chartData2={chartData2}
        chartData3={chartData3}
        chartData4={chartData4}
        sharedEmpChart={employmentSharedC}
        sharedEmpChartB={employmentChartsB}
        sharedEmpChartC={employmentChartsC}
        sharedConChart={ccChart}
        sharedIRChart={interestRateSharedContent}
        sharedMGRChart={migrationSharedC}
        sharedMGRChartB={migrationChartsB}
        sharedMGRChartC={migrationChartsC}
        boardChart={boardCharts}
        boardChartB={boardChartB}
        boardChartC={boardChartC}
        muskData={muskData}
        muskData2={muskData2}
        muskData3={muskData3}
        muskData4={muskData4}
        fourColTableData={fourColTableData}
        fourColTableData2={fourColTableData2}
        fourColTableData3={fourColTableData3}
        fourColTableData4={fourColTableData4}
        fourColTableName={fourColTableName}
        fourColTableName2={fourColTableName2}
        fourColTableName3={fourColTableName3}
        fourColTableName4={fourColTableName4}
        fourColFooterName={fourColFooterName}
        fiveColTableData={fiveColTableData}
        fiveColTableName={fiveColTableName}
        fiveColTableData2={fiveColTableData2}
        fiveColTableName2={fiveColTableName2}
        threeColTableData={threeColTableData}
        threeColTableName={threeColTableName}
        sevenColTableData={sevenColTableData}
        sevenColTableData2={sevenColTableData2}
        sevenColTableName={sevenColTableName}
        sevenColTableName2={sevenColTableName2}
        eightColTableData={eightColTableData}
        eightColTableName={eightColTableName}
        eightColTableData2={eightColTableData2}
        eightColTableName2={eightColTableName2}
        eightColTableData3={eightColTableData3}
        eightColTableName3={eightColTableName3}
        csvIframe={csvIframe}
      >
        <GlobalStyle />
      </Layout>
    )
  }
}

BoardTemplate.propTypes = propTypes

export default BoardTemplate

export const pageQuery = graphql`
  query BoardPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
        languages {
          defaultLangKey
          langs
        }
      }
    }

    contentfulBoards(id: { eq: $id }) {
      title
      slug
      headline
      boardPresidentName
      boardName
      boardRep
      renderedTitle

      boardPageType
      boardContentChartsA {
        image1
        image2
        image3
        image4
        image5
        image6
      }



      sharedContent {
        title
        consumerConfidenceCharts {
          image1
        }
        sharedHeadline

        sharedContent {
          childContentfulRichText {
            html
          }
        }
      }

      employmentSharedC {
        title
        sharedHeadline
        employmentCharts {
          image1
          image2  
          image3       
        }
        sharedContent {
          childContentfulRichText {
            html
          }
        }
        sharedHeadlineB
        sharedContentB {
          childContentfulRichText {
            html
          }
        }
        employmentChartsB {
          image1
          image2          
        }
        sharedHeadlineC
        sharedContentC {
          childContentfulRichText {
            html
          }
        }
        employmentChartsC {
          image1
          image2         
        }
      }

      migrationSharedC {
        sharedHeadline

        migrationCharts {
          image1
        }

        sharedContent {
          childContentfulRichText {
            html
          }
        }
        sharedContentB {
          childContentfulRichText {
            html
          }
        }
        migrationChartsB {
          image1
        }
        sharedContentC {
          childContentfulRichText {
            html
          }
        }
        migrationChartsC {
          image1
        }
      }

      interestRateSharedContent {
        sharedHeadline
        interestRateCharts {
          image1
        }
        sharedContent {
          childContentfulRichText {
            html
          }
        }
      }

      boardAboutSharedC {
        sharedHeadline
        sharedContent {
          childMarkdownRemark {
            html
          }
        }
      }

      boardInfo {
        childMarkdownRemark {
          html
        }
      }

      mainContent {
        childContentfulRichText {
          html
        }
      }
      additionalContent {
        childMarkdownRemark {
          html
        }
      }
      boardPresidentPhoto {
        fixed(width: 150, quality: 100) {
          src
          srcSet
          width
          height
        }
      }
      menuCompile {
        title
        boardSubMenu {
          title
          slug
        }
        boardsSubRef {
          title
          slug
        }
        boardMlsMenu {
          title
          slug
        }
      }
    }

    MuskHome1: allMuskHomeTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    MuskHome2: allMuskHomeTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    MuskHome3: allMuskHomeTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
    MuskHome4: allMuskHomeTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }

    AreaHome1: allAreaHomeTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    AreaHome2: allAreaHomeTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    CollHome1: allCollHomeTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
    CollHome2: allCollHomeTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
    ChatHome1: allChatHomeTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
          field_8
        }
      }
    }

    ChatHome2: allChatHomeTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
          field_8
        }
      }
    }

    GranHome1: allGranHomeTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }

    StjoHome1: allStjoHomeTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
        }
      }
    }

    NbreaHome1: allNbreaHomeTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
        }
      }
    }

    MoncHome1: allMoncHomeTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
          field_8
        }
      }
    }
    MoncHome2: allMoncHomeTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
          field_8
        }
      }
    }
    MoncHome3: allMoncHomeTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
          field_8
        }
      }
    }

    NsarHome1: allNsarHomeTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
        }
      }
    }

    NsarHome2: allNsarHomeTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
        }
      }
    }

    OreaHome1: allOreaHomeTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
        }
      }
    }

    OreaHome2: allOreaHomeTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
        }
      }
    }

    QuinHome: allQuinHomeTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
  }
`
