import React from 'react'
import '../Chart/chart-accordion.css'
import '../Chart/chart.module.css'
import charts from './chart.module.css'
import Lightbox from 'react-images'
import Gallery from 'react-photo-gallery'

const imgStyle = {
  width: '25%',
  display: 'inline-flex',
  padding: '20px',
}

const customRow = {
  background: '#edf0fe',
}

export default class ChartX extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentImage: 0,
    }
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
  }
  componentWillReceiveProps(nextProp) {
    console.log(nextProp, 'next')
  }

  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    })
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }

  render() {
    /* const chatHome = this.props.chatChart
    const chatRa = this.props.chatRaChart */
    const sharedmgrChart = this.props.sharedMGRChartB
    const name = this.props.name

    // const chatHomeRen = sharedmgrChart
    //   ? sharedmgrChart.map((item, id) => {
    //       return {
    //         src: item.fluid.src,
    //         width: 247,
    //         height: 169,
    //       }
    //     })
    //   : []

    const chatHomeRen = sharedmgrChart
      .filter(function(item) {
        if (!item.image) {
          return false // skip
        }
        return true
      })
      .map((item, id) => {
        return {
          src: item.image,
          width: 247,
          height: 169,
          alt: 'National Migration Chart' + ' ' + id,
        }
      })

      
    return (
      <div>
        <div className="container">
          <div className="row" style={customRow}>
            <div className={charts.tabContent}>
              {name}
              {chatHomeRen !== undefined && (
                <div className=".col-lg-">
                  <Gallery photos={chatHomeRen} onClick={this.openLightbox} />

                  <Lightbox
                    images={chatHomeRen}
                    onClose={this.closeLightbox}
                    onClickPrev={this.gotoPrevious}
                    onClickNext={this.gotoNext}
                    currentImage={this.state.currentImage}
                    isOpen={this.state.lightboxIsOpen}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
