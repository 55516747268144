import React from 'react'
import TableSheet from '../Table/sheet.module.css'
import { Table } from 'reactstrap'

export default class BoardTable extends React.Component {
  constructor(props) {
    super(props)
  }
  componentWillReceiveProps(nextProp) {}
  render() {
    const data = this.props.tableData
    const name = this.props.name

    return (
      <Table
        bordered
        className={TableSheet.tableBordered}
        style={{ marginTop: '40px' }}
      >
        <thead>
          <tr>
            <th colSpan="5" className="tableHeader boardTableHead">
              {name}
            </th>
          </tr>
        </thead>
        {data !== undefined && (
          <tbody className="BoardTable">
            {data.map((row, i) => (
              <tr scope="row" key={`${row.node.field1} ${i}`}>
                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_1,
                  }}
                />

                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_2,
                  }}
                />
                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_3,
                  }}
                />
                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_4,
                  }}
                />
                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_5,
                  }}
                />
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    )
  }
}
