import React from 'react'
import TableSheet from '../Table/sheet.module.css'
import { Table } from 'reactstrap'

const boardtablehead = {
  background: 'white',
}

export default class BoardTable extends React.Component {
  constructor(props) {
    super(props)
  }
  componentWillReceiveProps(nextProp) {
    console.log(nextProp, 'next')
  }
  render() {
    const data = this.props.tableData
    const name = this.props.name

    console.log('table', data)
    return (
      <Table
        bordered
        className={TableSheet.tableBordered}
        style={{ marginTop: '40px' }}
        style={{
          color: 'var(--textNormal)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out',
        }}
      >
        <thead>
          <tr boardtablehead={boardtablehead}>
            <th colSpan="8" className="tableHeader boardTableHead">
              {name}
            </th>
          </tr>
        </thead>
        {data !== undefined && (
          <tbody className="BoardTable">
            {data.map((row, i) => (
              <tr scope="row" key={`${row.node.field1} ${i}`}>
                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_1,
                  }}
                />

                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_2,
                  }}
                />
                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_3,
                  }}
                />
                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_4,
                  }}
                />
                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_5,
                  }}
                />
                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_6,
                  }}
                />
                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_7,
                  }}
                />
                <td
                  dangerouslySetInnerHTML={{
                    __html: row.node.field_8,
                  }}
                />
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    )
  }
}
